<template>
    <div class="pageWrapper">
        <!--div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back.svg" />
                    </router-link>
                </div>
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>Bitpays a cryptocurrency payment gateway solutions which targeted E-commerce, Gaming and Financial industry. The entire site theme is in a light grey blue theme which bring out the sense of professional technology feels. The design element is from a 3d cube block shape that represented blockchain.</p>

                <p>However, the site is still under progression, URL is not ready to preview yet.</p>

                <ul class="generalUl">
                    <li>
                        <label>Involved in</label>
                        <p>Logo Design, UI & UX Design</p>
                    </li>
                </ul>

            </div>
        </div>
        <div class="pageRight">
            <div class="inner">

                 <section>
                    <div class="styledHeading mb-3">
                        <h1>Logo Design</h1>
                    </div>
                    <div class="logoContainer">
                        <img class="logo-small" v-preview:scope-a src="@/assets/images/works/bitpays/logo.png">
                        <img class="logo-large" v-preview:scope-a src="@/assets/images/works/bitpays/logo_withtext.png">
                    </div>
                </section>


                <section>
                    <div class="styledHeading mb-3">
                        <h1>UI Kits</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/bitpays/uikits.png">
                </section>


                <section class="bitpaysContainer">
                    <div class="styledHeading mb-3">
                        <h1>Landing Page Design</h1>
                    </div>
                    <div class="screenshots">
                        <img v-preview:scope-a class="web" src="@/assets/images/works/bitpays/screenshot_landing_web.png">
                        <img v-preview:scope-a class="mobile" src="@/assets/images/works/bitpays/screenshot_landing_mobile.png">
                    </div>
                </section>

                <section style="padding-top: 10%;">
                    <div class="styledHeading mb-5">
                        <h1>Dashboard Interface</h1>
                    </div>
                    <img-comparison-slider class="coloured-slider">
                        <img slot="first" v-preview:scope-a src="@/assets/images/works/bitpays/dashboard_wireframe.png" />
                        <img slot="second" v-preview:scope-a src="@/assets/images/works/bitpays/dashboard_ui.png" />
                    </img-comparison-slider>

                    <p style="text-align: center; font-size: 0.875rem;">You may drag to see the comparison of wireframe and user interface.</p>
                </section>

                <section>
                    <div class="styledHeading mb-3">
                        <h1>User Flow</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/bitpays/userflow.png">
                </section>

            </div>
        </div-->

        <div class="workHeader bitpays">
            <div class="container">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 

                <img src="@/assets/images/works/bitpays/header_img.png" class="headerImg">

                <div class="headerContent">
                    <h1>{{pagetitle}}</h1>
                    <ul>
                        <li>UI Design</li>
                        <li>2020</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="workContent">
            <section>
                <div class="container">

                    <div class="flexContainer">
                        <div class="col-8">
                            <div class="liteHeading">Overview</div>
                            <p>Bitpays is a cryptocurrency payment gateway solutions which targeted E-commerce, Gaming and Financial industry. Customers are able to pay with a few selected cryptocurrencies to the merchant wallet through the platform. </p>
                        </div>
                        <div class="col-4 text-right">
                            <div class="liteHeading">Task</div>
                            <ul>
                                <li>UI Design</li>
                                <li>Logo Design</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="sectionGrey">
                <div class="container">

                    <div class="styledHeading">
                        <h2>Merchant Dashboard</h2>
                    </div>
                    <p>The bitpays merchant dashboard will be a platform for merchant to monitor and manage their daily transactions. Merchants are required to create their own wallet and setup their e-commerce website in the platform to start running the services in their own e-commerce shop. My task is to create the interface design and partially involved in the user flow planning as the major flow has set.</p>
                    
                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/bitpays/merchant_dashboard_1.png">
                        </div>
                        <div style="min-width:100%">
                            <img v-preview:scope-a src="@/assets/images/works/bitpays/merchant_dashboard_2.png">
                        </div>
                        <div style="min-width:100%">
                            <img v-preview:scope-a src="@/assets/images/works/bitpays/merchant_dashboard_3.png">
                        </div>
                    </Flicking>

                    <div class="spacing mb-1"></div>

                    <div class="flexContainer">
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Color Palatte</h4>
                            </div>
                            <img src="@/assets/images/works/bitpays/color_palatte.png" class="colorpalatte">
                        </div>
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Typography</h4>
                            </div>
                            <img src="@/assets/images/works/bitpays/typo.png"  class="typo">
                        </div>
                    </div>

                    <div class="spacing mb-5"></div>

                    <div class="styledHeading mb-3">
                        <h4>User Flow</h4>
                    </div>
                    <img src="@/assets/images/works/bitpays/flow.png">

                    <div class="spacing"></div>


                </div>
            </section>

            <section>
                <div class="container">

                    <div class="styledHeading">
                        <h2>Landing Page</h2>
                    </div>
                    <p>The bitpays landing page is design in a grey background to emphasize the white color with support of the design element - hexagon cube which represented as the blockchain element.</p>

                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-b src="@/assets/images/works/bitpays/landing_1.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-b src="@/assets/images/works/bitpays/landing_2.png">
                        </div>
                    </Flicking>

                </div>
            </section>

            <section class="sectionGrey">
                <div class="container">

                    <div class="styledHeading">
                        <h2>Logo Design</h2>
                    </div>
                    <p>The bitpays logo is based on a wallet shape and manipulate into “B” shape letter wallet.</p>

                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/bitpays/logo_v.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/bitpays/logo_h.png">
                        </div>
                    </Flicking>

                </div>
            </section>

            <div class="footer">
                <div class="container text-center">
                    
                    <div class="styledHeading">
                        <h3>Let's connect</h3>
                    </div>
                    <p>Get in touch for opportunities or just to say hi! 👋</p>

                    <div class="action">
                        <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank"><img src="@/assets/images/icon_linkedin.svg"></a>
                        <!--a href=""><img src="@/assets/images/icon_envelope.svg"></a-->
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>

import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import createPreviewDirective from "vue-photoswipe-directive";
import 'img-comparison-slider';
import { Flicking } from "@egjs/vue-flicking";

export default {
    name: 'bitpays',
    components: {
        Flicking: Flicking
    },
    directives: {
        preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
    },
    data () {
        return {
            pagetitle: 'Bitpays'
        }
    },
}
</script>

<style>
    .flicking-camera{
        display: flex;
    }
    .flicking-camera > div{
        margin: 0 .5rem;
        cursor: pointer;
    }
</style>